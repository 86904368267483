import React from 'react'
import Admin from './Admin'

function Users() {
  return (
    <>
    <Admin/>
      
    </>
  )
}

export default Users

